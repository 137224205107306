// HeroSection.tsx
import React from 'react';
import Button from '../commons/Button/button';
import * as styles from './HeroSection.module.css';

interface HeroSectionProps {
  title: string;
  subTitle: string;
  description: string;
  buttonTitle: string;
  imageSrc: string;
  imageAlt: string;
  ctaLink: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  title,
  subTitle,
  description,
  buttonTitle,
  imageSrc,
  imageAlt,
  ctaLink,
}) => {
  return (
    <section className={styles.topSection}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <span>
            <span className={styles.orange}>補助金・助成金</span>
          </span>
          <span>会社の可能性を最大限に広げたい方</span>
        </h1>
        <p className={styles.achievement}>
          情報収集から交付申請まで
          <br />
          <span>全面的にサポートします！</span>
        </p>
        <div className={styles.buttonWrapper}>
          <Button variant="contained" to="/contact">
            顧問相談
          </Button>
          <Button variant="gradient" to="/contact">
            お問い合わせ
          </Button>
        </div>
      </div>
      <div className={styles.image}>
        <img src="/hojojo6.jpg" alt="haikei" />
      </div>
    </section>
  );
};

export default HeroSection;

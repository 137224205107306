// extracted by mini-css-extract-plugin
export var ReasonSectionPrimary = "ReasonSection-module--ReasonSectionPrimary--8d48f";
export var ReasonSectionSecondary = "ReasonSection-module--ReasonSectionSecondary--0e31c";
export var largeImage = "ReasonSection-module--largeImage--32b59";
export var orange = "ReasonSection-module--orange--11044";
export var reasonContent = "ReasonSection-module--reasonContent--8201c";
export var reasonItem = "ReasonSection-module--reasonItem--9bf8c";
export var reasonItems = "ReasonSection-module--reasonItems--04773";
export var reasonNumber = "ReasonSection-module--reasonNumber--9119f";
export var reasonTitle = "ReasonSection-module--reasonTitle--aeafc";
export var reasons = "ReasonSection-module--reasons--af00b";
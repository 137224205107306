// src/components/CtrSection/CtrSection.tsx
import React from 'react';
import Button from '../commons/Button/button';
import * as styles from './CtrSection.module.css';

interface CtrSectionProps {
  title: string;
  subTitle: string;
  description: string;
}

const CtrSection: React.FC<CtrSectionProps> = ({
  title,
  subTitle,
  description,
}) => {
  return (
    <section className={styles.topSection}>
      <div className={styles.title}>
        <h1>
          補助金・助成金申請
          <br />
          お任せください！
        </h1>
      </div>

      <div className={styles.buttonWrapper}>
        <Button to="/contact" variant="gradient" size="large">
          顧問相談
        </Button>
        <Button to="/contact" variant="gradient" size="large">
          お問い合わせ
        </Button>
      </div>

      <div className={styles.explainWrapper}>
        <div className={styles.explain}>
          <p>
            JETSでは、中小企業の経営・人材・マーケティングにおけるサポートを実現します。
            当事務所は社会保険労務士、行政書士、FP（ファイナンシャルプランナー）3つの資格を組み合わせて総合的なサービスを展開しております。成長ポテンシャルを有する中小企業の様々な「トライ」を強力にサポートいたします！
          </p>
        </div>
      </div>

      <div className={styles.image}>
        <img src="/images/haikei3.jpg" alt="haikei" />
      </div>
    </section>
  );
};

export default CtrSection;

// src/components/ReasonSection.tsx

import React from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import * as styles from './ReasonSection.module.css';

type ColorType = 'primary' | 'secondary';

type ReasonSectionProps = {
  imageSrc: string;
  imageAlt: string;
  title: string;
  description: string;
  sectionId: string;
  subTitle: string;
  colorType: ColorType;
  ctrLink: string;
  ctrText: string;
  founderName: string;
};

const ReasonSectionRight: React.FC<ReasonSectionProps> = ({
  imageSrc,
  imageAlt,
  title,
  description,
  sectionId,
  subTitle,
  colorType,
  ctrLink,
  ctrText,
  founderName,
}) => {
  let ReasonSectionClass = styles.ReasonSectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    ReasonSectionClass = styles.ReasonSectionPrimary;
  } else if (colorType === 'secondary') {
    ReasonSectionClass = styles.ReasonSectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={ReasonSectionClass} id={sectionId}>
        <div className={styles.reasons}>
          <em>REASONS</em>
          <h2>補助金・助成金ドットコムでできること</h2>
        </div>
        <div className={styles.reasonItems}>
          <div className={styles.reasonItem}>
            <img
              src="/hojojo3.jpg"
              alt="haikei"
              className={styles.largeImage}
            />

            <div className={styles.reasonTitle}>
              <span className={styles.reasonNumber}>01</span>
              <h3>
                <span>
                  <span className={styles.orange}>補助金・助成金を</span>
                  <br />
                  情報収集
                </span>
              </h3>
            </div>
            <div className={styles.reasonContent}>
              <p>
                まずは補助金・助成金に関する情報を集めたいという方は、ご活用ください。補助金・助成金情報は随時追加・更新しています。
              </p>
            </div>
          </div>
          <div className={styles.reasonItem}>
            <img
              src="/hojojo4.jpg"
              alt="haikei"
              className={styles.largeImage}
            />

            <div className={styles.reasonTitle}>
              <span className={styles.reasonNumber}>02</span>
              <h3>
                <span>顧問制度で</span>
                <br />

                <span className={styles.orange}>安心のサポート</span>
              </h3>
            </div>
            <div className={styles.reasonContent}>
              <p>
                補助金や助成金の顧問制度を利用すれば、確実に申請手続きがスムーズになります。専門家がサポートするため、手続きミスを減らし、受給の可能性が高まります。顧問に依頼することで、煩雑な書類作成や提出を任せられます。自分で調べる時間を節約し、他の業務に集中しましょう。{' '}
              </p>
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default ReasonSectionRight;
